import { getCookieConsent } from '@booking/shared';
import { EventProps, TrackingEvent } from '@booking/types';
import { posthog } from 'posthog-js';
import TagManager from 'react-gtm-module';

export default function useAnalytics() {
  const cookieConsent = getCookieConsent(document.cookie);
  const setEventProps = (props: EventProps) => {
    const { event, target } = props;

    if (!target && cookieConsent?.marketing && cookieConsent?.statistics) {
      sendToGa(event);
      sendToPosthog(event);
    }

    if (target?.ga && cookieConsent?.marketing) {
      sendToGa(event);
    }

    if (target?.posthog && cookieConsent?.statistics) {
      sendToPosthog(event);
    }
  };

  const sendToGa = ({
    category = 'booking_web_v2',
    action,
    label,
    value,
  }: TrackingEvent) => {
    TagManager.dataLayer({
      dataLayer: {
        event: category,
        action,
        eventLabel: label,
        eventValue: value,
      },
    });
  };

  const sendToPosthog = ({
    action,
    category = 'booking_web_v2',
    city,
    clinic,
    daysUntil,
    label,
    message,
    practitionerId,
    service,
    speciality,
    time,
    value,
    vertical,
  }: TrackingEvent) => {
    posthog.capture(`${action}`, {
      category,
      city,
      clinic,
      daysUntil,
      label,
      message,
      practitionerId,
      service,
      speciality,
      time,
      value,
      vertical,
    });
  };
  return setEventProps;
}
