import { Clinics } from '@booking/api';
import {
  clinicsWithDistance,
  convertDistanceToUnit,
  getDateInTimezone,
  useClinicStore,
  useCookieStore,
  useDateStore,
  useNavigationStore,
  useNotificationStore,
  usePositionStore,
  useServiceTreeStore,
} from '@booking/shared';
import { Actions, Clinic, Locales, Option } from '@booking/types';
import {
  AnimatedStep,
  SelectPills,
  SpinnerWrapper,
  StepButton,
  StepWrapper,
} from '@booking/ui-web';
import NextAvailable from '@components/NextAvailable';
import { Icon } from '@drdropin-tech/theseus';
import useAnalytics from '@hooks/useAnalytics';
import { useQuery } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { posthog } from 'posthog-js';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { shallow } from 'zustand/shallow';

type SortKey = 'by-distance' | 'by-name' | 'by-available';
type SortMethods = {
  [key in SortKey]: {
    method: (a: Clinic, b: Clinic) => number;
  };
};

const sortMethod: SortMethods = {
  'by-distance': {
    method: (a: Clinic, b: Clinic) => {
      if (a.distance && b.distance) {
        return a?.distance - b?.distance;
      }
      return 0;
    },
  },
  'by-name': {
    method: (a: Clinic, b: Clinic) => a.name.localeCompare(b.name),
  },
  'by-available': {
    method: (a: Clinic, b: Clinic) => {
      if (a.nextAvailable && b.nextAvailable) {
        return a.nextAvailable - b.nextAvailable;
      }
      return 0;
    },
  },
};

export default function SelectClinicStep() {
  const locale = useRouter().locale as Locales;
  const isPosthogEnabled = useCookieStore((state) => state.isPosthogEnabled);

  const { t } = useTranslation('booking');
  const setEventProps = useAnalytics();

  const nextAvailableMap = useRef(new Map<string, number>());

  const { currStep, setQueries, advanceStep, queries, goToStep } =
    useNavigationStore(
      (state) => ({
        currStep: state.currStep,
        setQueries: state.setQueries,
        advanceStep: state.advanceStep,
        queries: state.queries,
        goToStep: state.goToStep,
      }),
      shallow,
    );

  const { getServiceUuid, getClinicIdsFromServiceTree } = useServiceTreeStore(
    (state) => ({
      getServiceUuid: state.getServiceUuid,
      getClinicIdsFromServiceTree: state.getClinicIdsFromServiceTree,
    }),
    shallow,
  );

  const showNextAvailable =
    queries.vertical === 'general' ||
    queries.speciality === 'gynecologist' ||
    queries.vertical === 'physical' ||
    queries.vertical === 'mental-health';

  const sortOptions: Option<SortKey>[] = [
    {
      icon: 'ArrowDown',
      key: t('steps.select_clinic.sort.options.by_name'),
      value: 'by-name',
    },
    {
      icon: 'MapPin',
      key: t('steps.select_clinic.sort.options.by_distance'),
      value: 'by-distance',
    },
  ];

  if (showNextAvailable && nextAvailableMap) {
    sortOptions.push({
      icon: 'Clock',
      key: t('steps.select_clinic.sort.options.by_available'),
      value: 'by-available',
    });
  }

  const { openNotification, closeNotification, isNotificationVisible } =
    useNotificationStore((state) => ({
      openNotification: state.openNotification,
      closeNotification: state.closeNotification,
      isNotificationVisible: state.isNotificationVisible,
    }));

  const { currentCoords, getLocation } = usePositionStore((state) => ({
    currentCoords: state.currentCoords,
    setCurrentCoords: state.setCurrentCoords,
    getLocation: state.getLocation,
  }));

  const [selectedOption, setSelectedOption] = useState(
    currentCoords ? sortOptions[1] : sortOptions[0],
  );

  const { setClinic } = useClinicStore()(
    (state) => ({
      setClinic: state.setClinic,
    }),
    shallow,
  );

  const setSelectedDate = useDateStore()((state) => state.setSelectedDate);

  const clinicIdsInServiceMap = getClinicIdsFromServiceTree(queries.service);
  const serviceUuid = getServiceUuid(queries.service);

  const { data: clinics, isLoading } = useQuery(
    ['clinics', nextAvailableMap],
    ({ signal }) => Clinics.getClinics({ signal }),
    {
      refetchOnWindowFocus: false,
      enabled: !!serviceUuid,
      keepPreviousData: true,
      select: (data) => {
        /*
         * Filter out the clinic if it exists in the service tree and is in the same city.
         * Also checks if the clinic is active
         */
        let filteredClinics = data.filter(
          (clinic: Clinic) =>
            clinic.address.city === queries.city &&
            clinicIdsInServiceMap?.includes(clinic.id) &&
            clinic.isActive,
        );

        if (currentCoords) {
          filteredClinics = clinicsWithDistance(currentCoords, filteredClinics);
        }

        if (nextAvailableMap.current) {
          filteredClinics = filteredClinics.map((clinic) => {
            const slot = nextAvailableMap.current.get(clinic.id);
            return { ...clinic, nextAvailable: slot };
          });
        }

        return filteredClinics;
      },
    },
  );

  const sortedClinics = useMemo(() => {
    return clinics?.sort(sortMethod[selectedOption.value].method);
  }, [clinics, selectedOption.value, currentCoords]);

  const changeSortOption = (option: Option<SortKey>) => {
    setSelectedOption(option);
    setEventProps({
      event: {
        action: Actions.ClinicSortOptionClicked,
        sortOption: option.value,
      },
    });
  };

  const handleClick = (clinic: Clinic) => {
    setClinic(clinic);
    setQueries({ clinicId: clinic.shortId });
    if (queries.vertical === 'mental-health') {
      advanceStep();
    } else {
      goToStep(currStep + 2);
    }
    setEventProps({
      event: {
        action: Actions.SelectClinic,
        service: queries.service,
        speciality: queries.speciality,
        city: queries.city,
        vertical: queries.vertical,
        clinic: clinic.shortId,
      },
    });
    setSelectedDate(getDateInTimezone());
    closeNotification();
  };

  useEffect(() => {
    if (selectedOption.value === 'by-distance') {
      getLocation({
        onSuccess: () => {
          if (isNotificationVisible) {
            closeNotification();
          }
          setSelectedOption(sortOptions[1]);
        },
        onError: () => {
          setSelectedOption(sortOptions[0]);
          openNotification();
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOption.value]);

  // CLINIC ADDRESS AB-TEST

  const isClinicAddressTest =
    process.env.NEXT_PUBLIC_ENV !== 'production' ||
    (isPosthogEnabled && posthog?.getFeatureFlag('CLINIC_ADDRESS') === 'test');

  function getClinicName(clinic: Clinic) {
    const splitName = clinic.name.split(', ');
    return splitName.length > 1 ? splitName[1] : clinic.name;
  }

  // END CLINIC ADDRESS AB-TEST

  const ClinicHeader = (clinic: Clinic) => {
    if (clinic.distance) {
      return (
        <div className="flex w-full justify-between">
          {getClinicName(clinic)}
          <div className="flex items-center">
            <Icon name="MapPin" iconSize="xs" className="mr-1" />
            <span className="w-full">
              {convertDistanceToUnit(clinic.distance)}
            </span>
          </div>
        </div>
      );
    } else {
      return getClinicName(clinic);
    }
  };

  const addToNextAvailableArray = (clinicId: string, time: number) => {
    nextAvailableMap.current.set(clinicId, time);
  };

  return (
    <StepWrapper
      title={
        queries.city
          ? `${t('steps.select_clinic.title')} ${queries.city}`
          : t('steps.select_clinic_loading.title')
      }
      isLoading={isLoading}
      stepWidth="max-w-[512px]"
    >
      {clinics && clinics.length > 1 && (
        <SelectPills
          options={sortOptions}
          selectedOption={selectedOption}
          onClick={changeSortOption}
        />
      )}
      {isLoading ? (
        <SpinnerWrapper />
      ) : (
        sortedClinics?.map((clinic: Clinic, index: number) => (
          <AnimatedStep key={clinic.id} order={index}>
            <StepButton
              title={ClinicHeader(clinic)}
              description={
                isClinicAddressTest
                  ? `${clinic.address.street}, ${clinic.address.zip} ${clinic.address.city}`
                  : undefined
              }
              onClick={() => {
                handleClick(clinic);
              }}
              DescriptionDecorator={
                showNextAvailable ? (
                  <NextAvailable
                    clinicId={clinic.id}
                    serviceId={serviceUuid}
                    index={index}
                    locale={locale}
                    addToNextAvailableArray={addToNextAvailableArray}
                  />
                ) : undefined
              }
              className="group"
            />
          </AnimatedStep>
        ))
      )}
    </StepWrapper>
  );
}
