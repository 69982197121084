import { timeZone } from '@booking/shared';
import { TimeSlot } from '@booking/types';
import { Tooltip, TooltipContent, TooltipTrigger } from '@booking/ui-web';
import classNames from 'classnames';
import { formatInTimeZone } from 'date-fns-tz';
import { useTranslation } from 'react-i18next';

type TimeButtonProps = {
  timeSlots: TimeSlot[];
  onClick: (time?: string, practitionerId?: string) => void;
  className?: string;
};

const TimeButtons = ({ timeSlots, className, onClick }: TimeButtonProps) => {
  const { t } = useTranslation('booking');
  return (
    <div className="grid grid-cols-3 gap-2">
      {timeSlots?.map(({ startTime, isAvailable }, index) => {
        const formattedTime = formatInTimeZone(
          new Date(startTime),
          timeZone,
          'HH:mm',
        );

        return (
          <Tooltip key={index} isAvailable={isAvailable}>
            <TooltipTrigger asChild>
              <button
                key={index}
                data-testid="time-button"
                aria-disabled={!isAvailable ? 'true' : undefined}
                className={classNames(
                  {
                    'bg-black/20 text-white': !isAvailable,
                    'available hover:bg-accent bg-white text-black':
                      isAvailable,
                  },
                  'w-full rounded-lg px-6 py-4',
                  className,
                )}
                disabled={!isAvailable}
                onClick={
                  !isAvailable ? undefined : () => onClick(formattedTime)
                }
              >
                {formattedTime}
              </button>
            </TooltipTrigger>
            <TooltipContent>
              <div className="rounded-md bg-white p-3 text-xs shadow-md">
                {t('steps.select_datetime.tooltip.time.no_available')}
              </div>
            </TooltipContent>
          </Tooltip>
        );
      })}
    </div>
  );
};

TimeButtons.displayName = 'TimeButtons';

export default TimeButtons;
