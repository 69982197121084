import {
  formatPrice,
  useCookieStore,
  useNavigationStore,
  usePrevious,
  useServiceTreeStore,
} from '@booking/shared';
import { Actions, Locales, Service, Speciality } from '@booking/types';
import { Accordion, AppLinks, StepWrapper, useModal } from '@booking/ui-web';
import specialityConfig from '@components/StepVariants/SelectSpeciality/config';
import LinkButton from '@components/StepVariants/SelectSpeciality/LinkButton';
import SpecialityItem from '@components/StepVariants/SelectSpeciality/SpecialityItem';
import useAnalytics from '@hooks/useAnalytics';
import { motion } from 'framer-motion';
import _get from 'lodash/get';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import posthog from 'posthog-js';
import { useEffect } from 'react';
import { shallow } from 'zustand/shallow';

const accordion = {
  show: {
    transition: {
      staggerChildren: 0.07,
    },
  },
};

const accordionItem = {
  hidden: { y: 5, opacity: 0 },
  show: { y: 0, opacity: 1 },
};

export default function SelectSpeciality() {
  const { t } = useTranslation('booking');
  const setEventProps = useAnalytics();

  const { openModal, isModalOpen } = useModal();
  const previousModalState = usePrevious(isModalOpen);
  const modalClosing = isModalOpen === false && previousModalState === true;

  const trackClick = (action: Actions) => {
    setEventProps({
      event: {
        action,
      },
    });
  };

  const toggleModal = async (service: Service, speciality: Speciality) => {
    openModal({
      content: {
        title: t(
          _get(
            specialityConfig,
            `modals.${speciality.shortId}.title`,
            specialityConfig.modals.default.title,
          ),
          { ...service, price: formatPrice(service.price) },
        ),
        description: t(
          _get(
            specialityConfig,
            `modals.${speciality.shortId}.description`,
            specialityConfig.modals.default.description,
          ),
          { service, price: formatPrice(service.price) },
        ),
        children: <AppLinks trackingFunction={trackClick} />,
        imageSrc: '/illustrations/bookingVideo.png',
      },
    });
  };

  const { advanceStep, setQueries, queries } = useNavigationStore(
    (state) => ({
      queries: state.queries,
      setQueries: state.setQueries,
      advanceStep: state.advanceStep,
    }),
    shallow,
  );

  const getSpecialities = useServiceTreeStore((state) => state.getSpecialities);
  const getLinks = useServiceTreeStore(
    (state) => state.getLinksByVerticalShortId,
  );

  const links = getLinks(queries.vertical);
  const specialities = [];

  // MOVE SPECIALITIES AB-TEST

  const isPosthogEnabled = useCookieStore((state) => state.isPosthogEnabled);

  function isSpecialistTestActive() {
    return (
      isPosthogEnabled &&
      posthog.getFeatureFlag('MOVE_SPECIALIST_SERVICES') === 'test'
    );
  }

  const testSpecialities = [];

  const isShortIdToMove = (shortId: string) => {
    return (
      shortId === 'weight-loss' ||
      shortId === 'headache' ||
      shortId === 'health-certificates'
    );
  };

  if (queries.vertical === 'general') {
    specialities.push(...getSpecialities(queries.vertical));

    if (isSpecialistTestActive()) {
      const _testSpecialities = getSpecialities('specialist');

      testSpecialities.push(
        ..._testSpecialities.filter((speciality) => {
          return isShortIdToMove(speciality.shortId);
        }),
      );
    }
  } else {
    specialities.push(...getSpecialities(queries.vertical));
  }

  // END AB-TEST

  const defaultOpenSpeciality =
    queries.speciality ||
    (specialities.length === 1 ? specialities[0]?.shortId : '');

  const isLoading = !queries.vertical;

  const locale = useRouter().locale as Locales;

  const handleClick = (
    service: Service,
    speciality: Speciality,
    vertical?: string,
  ) => {
    if (service.isVideo) {
      toggleModal(service, speciality);
      setEventProps({
        event: {
          action: Actions.OpenVideoModal,
          vertical: queries.vertical,
          speciality: speciality.shortId,
          service: service.shortId,
        },
      });
    } else {
      setQueries({
        service: service.shortId,
        speciality: speciality.shortId,
        vertical,
      });
      setEventProps({
        event: {
          action: Actions.SelectSpeciality,
          vertical: queries.vertical,
          speciality: speciality.shortId,
          service: service.shortId,
        },
      });
      advanceStep();
    }
  };

  useEffect(() => {
    if (modalClosing) {
      setEventProps({
        event: {
          action: Actions.CloseVideoModal,
          vertical: queries.vertical,
        },
      });
    }
  }, [isModalOpen]);

  const clickedPhysicalGuide = () => {
    setEventProps({
      event: {
        action: Actions.ClickedPhysicalGuide,
      },
    });
  };

  return (
    <StepWrapper
      title={t('steps.select_speciality.title')}
      isLoading={isLoading}
      className="text-black transition-all"
      stepWidth="max-w-[512px]"
    >
      <Accordion
        className="flex w-full flex-col gap-2"
        as={motion.div}
        initial="hidden"
        animate="show"
        variants={accordion}
        defaultActiveItem={defaultOpenSpeciality}
      >
        {specialities.map((speciality) => {
          return (
            <Accordion.Item
              key={speciality.id}
              label={speciality.shortId}
              as={motion.div}
              variants={accordionItem}
              data-testid="speciality-button"
              className="relative"
            >
              <SpecialityItem
                displayName={speciality.name[locale]}
                onClick={(service: Service) => {
                  handleClick(service, speciality, queries.vertical);
                }}
                {...speciality}
              />
            </Accordion.Item>
          );
        })}

        {// 'specialities.length + i' is used to animate in the correct order
        links?.map((link, i) => (
          <LinkButton
            index={specialities.length + i}
            link={link}
            key={link.id}
            rounded={true}
            icon={true}
          />
        ))}

        {testSpecialities.map((speciality) => {
          return (
            <Accordion.Item
              key={speciality.id}
              label={speciality.shortId}
              as={motion.div}
              variants={accordionItem}
              data-testid="speciality-button"
              className="relative"
            >
              <SpecialityItem
                displayName={speciality.name[locale]}
                onClick={(service: Service) => {
                  handleClick(service, speciality, 'specialist');
                }}
                {...speciality}
              />
            </Accordion.Item>
          );
        })}
      </Accordion>
      {queries.vertical === 'physical' ? (
        <div className="mt-4 flex flex-col items-center justify-center gap-6 rounded-lg bg-black/30 px-12 py-8 text-center text-sm text-white">
          <h4>{t('steps.select_speciality.physical.info_box_title')}</h4>
          <a
            className="rounded-full bg-white px-10 py-3 text-black"
            href={`https://advisor.drdropin.no/${locale === 'nb' ? '' : 'en'}`}
            onClick={clickedPhysicalGuide}
          >
            {t('steps.select_speciality.physical.info_box_link_title')}
          </a>
        </div>
      ) : null}
    </StepWrapper>
  );
}
