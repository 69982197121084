import { DayType, formatPrice } from '@booking/shared';
import { Icon } from '@drdropin-tech/theseus';
import { useTranslation } from 'react-i18next';

export default function DynamicPricingInfo({
  dayType,
  period,
  price,
  surcharge,
}: {
  dayType: DayType;
  period: string;
  price: number;
  surcharge: number;
}) {
  const { t } = useTranslation(['booking']);

  const surchargeType = dayType === 'day' ? period : dayType;
  return (
    <div className="flex items-center pb-2">
      <Icon name="Info" />
      <span className="ml-2 text-xs">
        {t(`steps.select_datetime.surcharge.${surchargeType}`, {
          surcharge: formatPrice(surcharge),
          totalPrice: formatPrice(price + surcharge),
        })}
      </span>
    </div>
  );
}
