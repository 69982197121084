import { Practitioners } from '@booking/api';
import {
  formatPrice,
  getDateTypeOfDay,
  getFormattedAppointmentDateTime,
  getSurchargePricing,
  useClinicStore,
  useCookieStore,
  useNavigationStore,
  usePersonalDataStore,
  useServiceTreeStore,
} from '@booking/shared';
import {
  Actions,
  BookingInfo,
  Clinic,
  Locales,
  Practitioner,
} from '@booking/types';
import PractitionerInfo from '@components/PractitionerInfo';
import useAnalytics from '@hooks/useAnalytics';
import { useQuery } from '@tanstack/react-query';
import { query } from '@utils/mapQuery';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import posthog from 'posthog-js';

type BookingSummaryProps = {
  hasClinic: boolean;
  hasTimeStamp: boolean;
  practitionerId?: string;
};

export default function BookingSummary({
  hasClinic,
  hasTimeStamp,
  practitionerId,
}: BookingSummaryProps) {
  const { personalDetails } = usePersonalDataStore()((state) => ({
    personalDetails: state.personalDetails,
  }));

  const queries = useNavigationStore((state) => state.queries);
  const {
    getServiceName,
    getSpecialityName,
    getNeedsDynamicPricing,
    getServicePrice,
  } = useServiceTreeStore(
    ({
      getServiceName,
      getSpecialityName,
      getNeedsDynamicPricing,
      getServicePrice,
    }) => ({
      getServiceName,
      getSpecialityName,
      getNeedsDynamicPricing,
      getServicePrice,
    }),
  );

  const locale = useRouter().locale as Locales;

  const specialityName = getSpecialityName(
    queries.vertical,
    queries.speciality,
    locale,
  );

  const serviceName =
    queries.service && getServiceName(queries.service, locale);

  const { clinic } = useClinicStore()((state) => ({ clinic: state.clinic }));

  let servicePrice = getServicePrice(queries.service) ?? 0;
  const needsDynamicPricing = getNeedsDynamicPricing(queries.service);
  if (servicePrice && needsDynamicPricing && hasTimeStamp) {
    const typeOfDay = getDateTypeOfDay(personalDetails.date!);
    const surchargePricing = getSurchargePricing(queries.service, typeOfDay);
    if (surchargePricing > 0) {
      servicePrice = servicePrice + surchargePricing;
    }
  }
  const { data: practitioner } = useQuery(
    ['practitioner', practitionerId],
    ({ signal }) =>
      Practitioners.getPractitioner({
        practitionerId,
        signal,
      }),
    {
      refetchOnWindowFocus: false,
      enabled: !!practitionerId,
    },
  );

  return (
    <div className="m-auto mb-10 flex w-full rounded-lg bg-black/20 p-6">
      <SummaryInfo
        hasClinic={hasClinic}
        hasTimeStamp={hasTimeStamp}
        serviceName={serviceName}
        locale={locale}
        personalDetails={personalDetails}
        specialityName={specialityName}
        clinic={clinic}
        practitioner={practitioner}
        price={servicePrice}
      />
    </div>
  );
}

const SummaryInfoSkeleton = () => {
  return (
    <div className="flex flex-col gap-4">
      <div className="flex items-center gap-4">
        <div className="flex flex-col gap-4">
          <div className="skeleton h-4 w-20 rounded-md"></div>
          <div className="skeleton h-4 w-28 rounded-md"></div>
        </div>
      </div>
      <div className="skeleton h-20 w-40 rounded-md"></div>
    </div>
  );
};

type SummaryInfoProps = {
  hasTimeStamp: boolean;
  hasClinic: boolean;
  clinic?: Clinic;
  specialityName?: string;
  serviceName?: string;
  personalDetails: BookingInfo;
  locale: Locales;
  practitioner?: Practitioner;
  price?: number;
};

const SummaryInfo = (props: SummaryInfoProps) => {
  const {
    hasTimeStamp,
    hasClinic,
    clinic,
    specialityName,
    serviceName,
    personalDetails,
    locale,
    practitioner,
    price,
  } = props;
  const { t } = useTranslation(['booking', 'common']);
  const setEventProps = useAnalytics();
  const isPosthogEnabled = useCookieStore((state) => state.isPosthogEnabled);

  const isLoading =
    !clinic?.name &&
    locale &&
    !serviceName &&
    !specialityName &&
    !personalDetails.date &&
    !price;

  const showPriceInSummary =
    isPosthogEnabled &&
    posthog?.getFeatureFlag('show-price-in-booking-summary') === 'test';

  return isLoading ? (
    <SummaryInfoSkeleton />
  ) : (
    <div className="flex w-full flex-col justify-start">
      {practitioner ? (
        <PractitionerInfo practitioner={practitioner} locale={locale} />
      ) : null}

      {hasTimeStamp && (
        <p className="pb-2">
          {getFormattedAppointmentDateTime(
            personalDetails.date!,
            personalDetails.time!,
            locale,
          )}
        </p>
      )}

      <p className="text-[17px] font-medium leading-7">
        {specialityName}, {serviceName}
      </p>

      {hasClinic && (
        <p className="text-[17px] font-normal">
          {clinic?.name}
          <br />
          {clinic?.address.street}, {clinic?.address.zip} {clinic?.address.city}
        </p>
      )}
      {price !== undefined && !showPriceInSummary ? (
        <p id="price" className="pt-2 text-[17px]">
          <div>
            <span className="font-medium">{`${t(
              'steps.confirm.price',
            )}: `}</span>
            {formatPrice(price)},-
          </div>
          {price > 0 ? <div>{t('steps.confirm.price_info')}</div> : null}
        </p>
      ) : null}
      <div className="mt-6 w-1/2 rounded-full border border-white py-2 text-center no-underline sm:w-1/3">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={
            clinic
              ? `https://maps.google.com/?q=${query(clinic)}`
              : 'https://maps.google.com'
          }
          onClick={() => {
            setEventProps({
              event: {
                action: Actions.OpenMap,
              },
            });
          }}
        >
          <span className="text-lg text-white">
            {t('map.button_text', { ns: 'common' })}
          </span>
        </a>
      </div>
    </div>
  );
};
