import { Clinics } from '@booking/api';
import { useNavigationStore, useServiceTreeStore } from '@booking/shared';
import { Actions, Clinic } from '@booking/types';
import { AnimatedStep, StepButton, StepWrapper } from '@booking/ui-web';
import useAnalytics from '@hooks/useAnalytics';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'next-i18next';

export default function SelectLocationStep() {
  const { queries, setQueries, advanceStep } = useNavigationStore((state) => ({
    queries: state.queries,
    setQueries: state.setQueries,
    advanceStep: state.advanceStep,
  }));
  const { service, speciality, vertical } = queries;
  const setEventProps = useAnalytics();

  const getClinicIdsFromServiceTree = useServiceTreeStore(
    (state) => state.getClinicIdsFromServiceTree,
  );

  const servicesInClinics = getClinicIdsFromServiceTree(service);

  const clinics = useQuery<Clinic[]>(
    ['clinics'],
    ({ signal }) => Clinics.getClinics({ signal }),
    {
      keepPreviousData: true,
      select: (data) => {
        const deduped: Clinic[] = [];

        for (const clinic of data) {
          /*
           * Check if the clinic exists in the service tree and is in the same city.
           * Also checks if the clinic is active
           */
          if (
            clinic.address.city &&
            !deduped.find((c) => clinic.address.city === c.address.city) &&
            servicesInClinics?.includes(clinic.id) &&
            clinic.isActive
          ) {
            deduped.push(clinic);
          }
        }
        return deduped;
      },
    },
  );

  const { t } = useTranslation('booking');

  const handleClick = (city: string) => {
    advanceStep();
    setQueries({ city });
    setEventProps({
      event: {
        action: Actions.SelectLocation,
        vertical,
        service,
        speciality,
        city,
      },
    });
  };

  return (
    <StepWrapper
      title={t('steps.select_area.title')}
      isLoading={clinics.isLoading}
      stepWidth="max-w-[512px]"
    >
      {clinics?.data?.map(({ address }, index) => (
        <AnimatedStep key={`${address.city}_${index}`} order={index}>
          <StepButton
            title={address.city}
            onClick={() => {
              handleClick(address.city);
            }}
          />
        </AnimatedStep>
      ))}
    </StepWrapper>
  );
}
