import { useNavigationStore, useServiceTreeStore } from '@booking/shared';
import { Actions, Locales, Vertical } from '@booking/types';
import { StepWrapper } from '@booking/ui-web';
import useAnalytics from '@hooks/useAnalytics';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useCallback } from 'react';
import { shallow } from 'zustand/shallow';

export default function SelectVerticalStep() {
  const locale = useRouter().locale as Locales;
  const { setQueries, advanceStep } = useNavigationStore(
    (state) => ({
      setQueries: state.setQueries,
      advanceStep: state.advanceStep,
    }),
    shallow,
  );

  const { serviceTree } = useServiceTreeStore((state) => ({
    serviceTree: state.serviceTree,
  }));

  const setEventProps = useAnalytics();
  const { t } = useTranslation('booking');

  const handleClick = useCallback(
    (vertical: Vertical) => {
      setEventProps({
        event: {
          action: Actions.SelectVertical,
          vertical,
        },
      });
      setQueries({ vertical });
      advanceStep();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setEventProps, setQueries, advanceStep],
  );

  return (
    <StepWrapper
      title={t('steps.select_vertical.title')}
      className="text-black transition-all"
      stepWidth="max-w-4xl"
    >
      <div className="flex flex-col justify-center gap-3">
        {serviceTree
          .sort((vertA, vertB) => vertA.order - vertB.order)
          .map(({ id, description, name, shortId }) => (
            <button
              key={id}
              type="button"
              onClick={() => handleClick(shortId as Vertical)}
              className="hover:bg-accent focus:bg-accent group flex w-full flex-row items-center gap-4 rounded-lg bg-white p-6 text-black transition-all"
              data-testid={shortId}
            >
              <figure className="group-hover:border-secondary h-20 w-20 flex-none rounded-full border-4 border-transparent group-hover:border-4">
                <Image
                  src={verticalIllustrationMap[shortId as Vertical]}
                  alt=""
                  width={120}
                  height={120}
                  className="group-hover:bg-secondary group-focus:bg-secondary rounded-full p-1 transition-all"
                  style={{
                    maxWidth: '100%',
                    height: 'auto',
                  }}
                />
              </figure>
              <div className="prose prose-p:mt-0 text-left">
                <h4 className="text-[20px] font-medium">{name[locale]}</h4>
                {description?.[locale] ? <p>{description?.[locale]}</p> : null}
              </div>
            </button>
          ))}
      </div>
    </StepWrapper>
  );
}

const verticalIllustrationMap: Record<Vertical, string> = {
  general: '/illustrations/door-with-sign.png',
  specialist: '/illustrations/doctor.png',
  physical: '/illustrations/physical.png',
  dermatologist: '/illustrations/DermaMan.png',
  gynecologist: '/illustrations/doctor.png',
  'eye-doctor': '/illustrations/EyeDoctor.png',
  'mental-health': '/illustrations/heart.png',
  psychology: '/illustrations/heart.png',
  covid: '/illustrations/covid.png',
};
