import { getDateInTimezone, parseDay, timeZone } from '@booking/shared';
import { Actions } from '@booking/types';
import { cva } from 'class-variance-authority';
import { enGB, nb } from 'date-fns/locale';
import { formatInTimeZone } from 'date-fns-tz';
import { TFunction } from 'i18next';
import { useRouter } from 'next/router';

import { Tooltip, TooltipContent, TooltipTrigger } from './Tooltip';

type Props = {
  day: string;
  selectedDay: string;
  isAvailable: boolean;
  onClick: (day: string) => void;
  className?: string;
  i18nFunction: TFunction;
  trackingEvent: (action: Actions, day: string) => void;
};

export default function DateButton({
  day,
  selectedDay,
  className,
  isAvailable,
  onClick,
  i18nFunction,
  trackingEvent,
}: Props) {
  const dateFnsLocale = useRouter().locale === 'en' ? enGB : nb;
  const t = i18nFunction;
  const isDisabled = !isAvailable;

  const [dayNumber, dayName, monthName] = formatInTimeZone(
    parseDay(day),
    timeZone,
    'dd-E-MMM',
    { locale: dateFnsLocale },
  ).split('-');

  const isSelected = day === selectedDay;
  const isToday = getDateInTimezone() === day;

  const buttonStyle = cva(['w-full', className], {
    variants: {
      intent: {
        selected: [
          'selected',
          'bg-accent',
          'ring-offset-primary',
          'ring-2',
          'text-black',
          'ring-offset-2',
          'outline-none',
          'ring-accent',
        ],
        disabled: ['bg-black/20', 'text-white'],
        default: ['bg-white', 'text-black', 'hover:bg-accent'],
      },
    },
  });

  const handleClick = () => {
    if (isDisabled) {
      trackingEvent(Actions.SelectDateButtonDisabled, day);
      return;
    }

    onClick(day);
  };

  return (
    <Tooltip isAvailable={isAvailable}>
      <TooltipTrigger asChild>
        <button
          key={day}
          id={`date-button-${day}`}
          onClick={handleClick}
          className={buttonStyle({
            intent: isSelected
              ? 'selected'
              : isDisabled
              ? 'disabled'
              : 'default',
          })}
          aria-disabled={isDisabled ? 'true' : undefined}
          data-testid="date-button"
        >
          <span className="text-[15px] leading-5">
            {isToday ? t('today') : dayName}
          </span>

          <span className="font-medium leading-5">{dayNumber}</span>

          <span className="text-[15px] leading-5">{monthName}</span>
        </button>
      </TooltipTrigger>

      <TooltipContent>
        <div className="w-fit-content h-auto max-w-[120px] rounded-md bg-white p-2 text-center text-xs text-black shadow-md">
          {t('steps.select_datetime.tooltip.date.no_available', {
            ns: 'booking',
          })}
        </div>
      </TooltipContent>
    </Tooltip>
  );
}
