import { Appointments } from '@booking/api';
import { locales, timeZone } from '@booking/shared';
import { Locales } from '@booking/types';
import { Icon } from '@drdropin-tech/theseus';
import { useQuery } from '@tanstack/react-query';
import { formatRelative, parseISO } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { useTranslation } from 'next-i18next';

type AvailabilityDecoratorProps = {
  clinicId?: string;
  serviceId: string;
  index: number;
  locale: Locales;
  addToNextAvailableArray: (clinicId: string, time: number) => void;
};

export default function NextAvailable({
  clinicId,
  serviceId,
  index,
  locale = 'nb',
  addToNextAvailableArray,
}: AvailabilityDecoratorProps) {
  const { t } = useTranslation('booking');
  const now = new Date().toISOString();

  const { data: nextAvailableData, isLoading } = useQuery(
    ['next-available', clinicId, serviceId],
    ({ signal }) =>
      Appointments.getNextAvailable({
        clinicId,
        serviceId,
        fromDate: now,
        signal,
      }),
    {
      enabled: !!serviceId,
      select: ([nextAvailableSlot]) => {
        if (nextAvailableSlot) {
          const zonedDate = utcToZonedTime(
            parseISO(nextAvailableSlot.startTime),
            timeZone,
          );
          addToNextAvailableArray(
            nextAvailableSlot.clinicId,
            zonedDate.getTime(),
          );
          return formatRelative(zonedDate, new Date(), {
            locale: locales[locale],
          });
        }
      },
    },
  );

  if (!nextAvailableData && !isLoading) return null;

  if (isLoading) {
    return (
      <span
        className="border-accent skeleton-available w-30 group-hover:border-primary mt-2 flex h-7 items-center gap-2 rounded-full border px-3 py-1"
        style={{
          animationDelay: `${index * 0.1}s`,
        }}
      />
    );
  }

  return (
    <div className="border-accent bg-accent group-hover:border-primary mt-2 flex items-center gap-2 rounded-full border px-3 py-1">
      <Icon name="Clock" iconSize="xs" />
      <span className="italic">{t('steps.select_clinic.next_timeslot')}</span>
      <span className="flex items-center gap-2 font-medium">
        {nextAvailableData}
      </span>
    </div>
  );
}
