import {
  formatPrice,
  getLowestPrice,
  useNavigationStore,
} from '@booking/shared';
import { Locales, Service, Speciality } from '@booking/types';
import {
  Accordion,
  ButtonBadge,
  HighlightedIcon,
  StepListItem,
} from '@booking/ui-web';
import LinkButton from '@components/StepVariants/SelectSpeciality/LinkButton';
import { Icon } from '@drdropin-tech/theseus';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

type Props = {
  onClick: (service: Service) => void;
  displayName: string;
  displayDescription?: string;
  label?: string;
} & Speciality;

export default function SpecialityItem({
  displayName,
  displayDescription,
  services,
  links,
  label,
  onClick,
}: Props) {
  const locale = useRouter().locale as Locales;
  const { t } = useTranslation('booking');

  const queries = useNavigationStore((state) => state.queries);

  if (services.length === 1) {
    const service = services[0];
    const serviceDescription = service.description?.[locale];

    return (
      <StepListItem
        className="group gap-1 rounded-xl bg-white"
        onClick={() => onClick(service)}
      >
        {service.membershipCampaignId && (
          <ButtonBadge title={t('partner.highlight.title')} />
        )}

        {/* If partner is storebrand, add a badge with 10% off */}
        {queries.partner === 'storebrand' && (
          <ButtonBadge title={t('partner.highlight.title')} />
        )}

        <p className="flex w-full flex-col justify-between self-center text-left font-normal md:text-base md:leading-7">
          <span className="flex w-full items-center justify-between text-sm">
            {displayName ? (
              <span>{displayName}</span>
            ) : (
              <span className="text-[red]">Translation missing</span>
            )}
            <span className="flex items-center gap-2 px-1">
              <span className="w-fit">{formatPrice(service.price)},-</span>
              <HighlightedIcon
                icon={service.isVideo ? 'Video' : 'ChevronRight'}
              />
            </span>
          </span>
          <span className="flex text-xs">
            {serviceDescription ? <span>{serviceDescription}</span> : null}
          </span>
        </p>
      </StepListItem>
    );
  }
  const lowestPrice = getLowestPrice(services, links);
  return (
    <>
      <Accordion.Button label={label}>
        {({ isSelected }: { isSelected: boolean }) => (
          <>
            <p className="flex w-full items-center justify-between gap-2 text-left font-normal md:text-base md:leading-7">
              <span className="flex w-full items-center justify-between gap-2 text-sm">
                <span>{displayName}</span>
                <span className="flex items-center gap-2">
                  <span className="whitespace-nowrap">
                    {t('steps.select_speciality.price_from', {
                      price: formatPrice(lowestPrice),
                    })}
                  </span>
                  <HighlightedIcon
                    icon="ChevronDown"
                    shouldHighlight={isSelected}
                  />
                </span>
              </span>
            </p>
            {displayDescription ? <span>{displayDescription}</span> : null}
          </>
        )}
      </Accordion.Button>
      <Accordion.Panel label={label}>
        {({ isSelected }: { isSelected: boolean }) => (
          <>
            {services
              .sort((a, b) => a.order - b.order)
              .map((service) => {
                const displayName = service.name[locale];
                const hasDynamicPrice = service.hasDynamicPrice ?? false;
                const isFree = service.price === 0;
                const price =
                  hasDynamicPrice && !isFree
                    ? t('steps.select_speciality.price_from', {
                        price: formatPrice(service.price),
                      })
                    : `${formatPrice(service.price)},-`;
                return (
                  <StepListItem
                    key={service.id}
                    onClick={() => onClick(service)}
                    disabled={!isSelected}
                    className="relative"
                  >
                    <div className="flex w-full flex-col text-start">
                      <div className="flex justify-between gap-4 text-sm">
                        {displayName && (
                          <div>
                            <span className="mr-3 text-start">
                              {displayName}
                            </span>
                            {service.isVideo && (
                              <Icon
                                name="Video"
                                className="inline-block h-5 w-5"
                              />
                            )}
                          </div>
                        )}
                        <div className="">
                          <span className="truncate text-sm">{price}</span>
                          {service.membershipCampaignId && (
                            <ButtonBadge
                              title={t('partner.highlight.title')}
                              className="!static ml-1 inline-block"
                            />
                          )}
                        </div>
                      </div>
                      <div className="flex items-center justify-between">
                        {service.description?.[locale] && (
                          <div className="w-full pt-2 text-xs">
                            {service.description?.[locale]}
                          </div>
                        )}
                      </div>
                    </div>
                  </StepListItem>
                );
              })}

            {links?.map((link, i) => (
              <LinkButton
                index={i}
                link={link}
                key={link.id}
                rounded={false}
                icon={false}
              />
            ))}
          </>
        )}
      </Accordion.Panel>
    </>
  );
}
