'use client'
"use strict";
var __create = Object.create;
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __getProtoOf = Object.getPrototypeOf;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toESM = (mod, isNodeMode, target) => (target = mod != null ? __create(__getProtoOf(mod)) : {}, __copyProps(
  // If the importer is in node compatibility mode or this is not an ESM
  // file that has been converted to a CommonJS file using a Babel-
  // compatible transform (i.e. "__esModule" has not been set), then set
  // "default" to the CommonJS "module.exports" for node compatibility.
  isNodeMode || !mod || !mod.__esModule ? __defProp(target, "default", { value: mod, enumerable: true }) : target,
  mod
));
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);

// src/index.ts
var src_exports = {};
__export(src_exports, {
  Appointments: () => appointments_exports,
  Clinics: () => clinics_exports,
  ExorLive: () => exorlive_exports,
  Partners: () => partners_exports,
  Practitioners: () => practitioners_exports,
  SMS: () => sms_exports,
  Services: () => services_exports,
  Users: () => users_exports,
  apiClient: () => apiClient
});
module.exports = __toCommonJS(src_exports);

// src/apiClient.ts
var import_axios = __toESM(require("axios"));
var apiClient = import_axios.default.create();
apiClient.interceptors.request.use((config) => {
  if (!apiClient.defaults.baseURL) {
    console.error("Missing baseURL!");
  }
  return config;
});

// src/appointments.ts
var appointments_exports = {};
__export(appointments_exports, {
  createAppointment: () => createAppointment,
  getAllAppointmentsForUser: () => getAllAppointmentsForUser,
  getNextAvailable: () => getNextAvailable,
  getTimeSlots: () => getTimeSlots,
  initializePayment: () => initializePayment
});
var qs = __toESM(require("qs"));
var ENDPOINT = "/v3/appointments";
async function getNextAvailable({
  clinicId,
  serviceId,
  practitionerIds,
  fromDate,
  membershipCampaignId,
  signal
}) {
  const response = await apiClient.get(`${ENDPOINT}/next-available`, {
    params: {
      clinicId,
      serviceId,
      practitionerIds,
      fromDate,
      membershipCampaignId
    },
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: "repeat" });
    },
    signal
  });
  return response.data;
}
async function getTimeSlots({
  serviceId,
  clinicId,
  fromDate,
  toDate,
  practitionerId,
  signal
}) {
  const clinicIdParam = clinicId ? `&clinicId=${clinicId}` : "";
  const practitionerIdParam = practitionerId ? `&practitionerId=${practitionerId}` : "";
  const response = await apiClient.get(
    `${ENDPOINT}/timeslots?serviceId=${serviceId}${clinicIdParam}&fromDate=${fromDate}&toDate=${toDate}${practitionerIdParam}`,
    { signal }
  );
  return response.data;
}
async function createAppointment({
  signal,
  bookingData,
  token
}) {
  let headers;
  if (token) {
    headers = { Authorization: `Bearer ${token}` };
  }
  const response = await apiClient.post(`${ENDPOINT}`, bookingData, {
    headers,
    signal
  });
  return response.data;
}
async function initializePayment({
  signal,
  paymentData,
  token
}) {
  let headers;
  if (token) {
    headers = { Authorization: `Bearer ${token}` };
  }
  const response = await apiClient.post(
    `${ENDPOINT}/initialize-payment`,
    paymentData,
    { headers, signal }
  );
  return response.data;
}
async function getAllAppointmentsForUser({
  signal,
  token
}) {
  const response = await apiClient.get(`${ENDPOINT}/`, {
    headers: { Authorization: `Bearer ${token}` },
    signal
  });
  return response.data;
}

// src/clinics.ts
var clinics_exports = {};
__export(clinics_exports, {
  getClinics: () => getClinics
});
var ENDPOINT2 = "/v3/clinics";
async function getClinics(params) {
  const { serviceId, city, signal } = params;
  const response = await apiClient.get(`${ENDPOINT2}?filterInactive=true`, {
    params: {
      ...serviceId ? { serviceId } : {},
      ...city ? { city } : {}
    },
    signal
  });
  return response.data;
}

// src/exorlive.ts
var exorlive_exports = {};
__export(exorlive_exports, {
  getUrl: () => getUrl
});
var ENDPOINT3 = "/v3/exorlive";
async function getUrl({
  signal,
  token
}) {
  let headers;
  if (token) {
    headers = { Authorization: `Bearer ${token}` };
  }
  const response = await apiClient.get(`${ENDPOINT3}/url`, {
    headers,
    signal
  });
  return response.data;
}

// src/partners.ts
var partners_exports = {};
__export(partners_exports, {
  createPartnerMembership: () => createPartnerMembership,
  deletePartnerMembership: () => deletePartnerMembership,
  getMembership: () => getMembership,
  listMembershipsCampaignsForPartner: () => listMembershipsCampaignsForPartner,
  listMembershipsForUser: () => listMembershipsForUser,
  listPartners: () => listPartners,
  listPartnersWithCustomOnboarding: () => listPartnersWithCustomOnboarding
});
var ENDPOINT4 = "/v1/partners";
async function getMembership({ signal, token }, membershipId) {
  let headers;
  if (token) {
    headers = { Authorization: `Bearer ${token}` };
  }
  const response = await apiClient.get(
    `${ENDPOINT4}/membership/${membershipId}`,
    { headers, signal }
  );
  return response.data;
}
async function listMembershipsForUser({
  signal,
  token
}) {
  let headers;
  if (token) {
    headers = { Authorization: `Bearer ${token}` };
  }
  const response = await apiClient.get(`${ENDPOINT4}/memberships`, {
    headers,
    signal
  });
  return response.data;
}
async function listPartners({
  signal,
  token
}) {
  let headers;
  if (token) {
    headers = { Authorization: `Bearer ${token}` };
  }
  const response = await apiClient.get(`${ENDPOINT4}`, {
    headers,
    signal
  });
  return response.data;
}
async function listPartnersWithCustomOnboarding({
  signal,
  token
}) {
  let headers;
  if (token) {
    headers = { Authorization: `Bearer ${token}` };
  }
  const response = await apiClient.get(
    `${ENDPOINT4}/custom-onboarding`,
    { headers, signal }
  );
  return response.data;
}
async function listMembershipsCampaignsForPartner({ signal, token }, partnerId) {
  let headers;
  if (token) {
    headers = { Authorization: `Bearer ${token}` };
  }
  const response = await apiClient.get(`${ENDPOINT4}/memberships/${partnerId}`, {
    headers,
    signal
  });
  return response.data;
}
async function createPartnerMembership({ signal, token }, data) {
  let headers;
  if (token) {
    headers = { Authorization: `Bearer ${token}` };
  }
  const response = await apiClient.post(`${ENDPOINT4}/memberships`, data, {
    headers,
    signal
  });
  return response.data;
}
async function deletePartnerMembership({ signal, token }, membershipId) {
  let headers;
  if (token) {
    headers = { Authorization: `Bearer ${token}` };
  }
  await apiClient.delete(`${ENDPOINT4}/memberships/${membershipId}`, {
    headers,
    signal
  });
}

// src/practitioners.ts
var practitioners_exports = {};
__export(practitioners_exports, {
  getPractitioner: () => getPractitioner,
  getPractitioners: () => getPractitioners
});
var ENDPOINT5 = "/v3/practitioners";
async function getPractitioners(props) {
  const { clinicId, serviceId, practitionerId, signal } = props;
  const clinicIdParam = clinicId ? `&clinicId=${clinicId}` : "";
  const serviceIdParam = serviceId ? `&serviceId=${serviceId}` : "";
  const practitionerIdParam = practitionerId ? `&practitionerId=${practitionerId}` : "";
  const response = await apiClient(
    `${ENDPOINT5}?${serviceIdParam}${clinicIdParam}${practitionerIdParam}`,
    { signal }
  );
  return response.data.sort((a, b) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  });
}
async function getPractitioner(props) {
  const { practitionerId, signal } = props;
  const response = await apiClient(`${ENDPOINT5}/${practitionerId}`, {
    signal
  });
  return response.data;
}

// src/services.ts
var services_exports = {};
__export(services_exports, {
  getServiceTree: () => getServiceTree,
  getServices: () => getServices
});
var ENDPOINT6 = "/v3/services";
async function getServices() {
  const response = await apiClient.get(`${ENDPOINT6}`);
  return response.data;
}
async function getServiceTree({
  partnerId,
  partner,
  token,
  signal
}) {
  let headers;
  if (token) {
    headers = { Authorization: `Bearer ${token}` };
  }
  const response = await apiClient.get(`${ENDPOINT6}/tree`, {
    params: { partnerId, partner },
    headers,
    signal
  });
  return response.data;
}

// src/sms.ts
var sms_exports = {};
__export(sms_exports, {
  getCode: () => getCode,
  verifyCode: () => verifyCode
});
var ENDPOINT7 = "/twilio/sms/verification";
async function getCode({
  phoneNumber,
  locale = "en",
  version = "v1"
}) {
  const response = await apiClient.post(`/${version}${ENDPOINT7}/initiate`, {
    phoneNumber,
    locale
  });
  return response.data;
}
async function verifyCode({
  phoneNumber,
  code,
  version = "v1"
}) {
  const response = await apiClient.post(`/${version}${ENDPOINT7}/verify-code`, {
    phoneNumber,
    code
  });
  return response.data;
}

// src/users.ts
var users_exports = {};
__export(users_exports, {
  getCurrentUser: () => getCurrentUser,
  updateCurrentUser: () => updateCurrentUser
});
var ENDPOINT8 = "/v1/users";
async function getCurrentUser({
  signal,
  token
}) {
  let headers;
  if (token) {
    headers = { Authorization: `Bearer ${token}` };
  }
  const response = await apiClient.get(`${ENDPOINT8}/current`, {
    headers,
    signal
  });
  return response.data;
}
async function updateCurrentUser({
  signal,
  token,
  user
}) {
  let headers;
  if (token) {
    headers = { Authorization: `Bearer ${token}` };
  }
  const response = await apiClient.put(`${ENDPOINT8}/current`, user, {
    headers,
    signal
  });
  return response.data;
}
// Annotate the CommonJS export names for ESM import in node:
0 && (module.exports = {
  Appointments,
  Clinics,
  ExorLive,
  Partners,
  Practitioners,
  SMS,
  Services,
  Users,
  apiClient
});
